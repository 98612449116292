


import {Component, Prop} from 'vue-property-decorator';
import {TContact} from '@/_types/contact.type';
import EwAvatar from '@/_modules/standalone-company/components/UI/ew-avatar/ew-avatar.vue';
import iconUnFavorite from '@/_modules/icons/components/icon-ew-unfavorite.vue';
import iconFavorite from '@/_modules/icons/components/icon-ew-favorite.vue';
import iconShare from '@/_modules/icons/components/icon-ew-share.vue';
import NotificationsMixin from '@/_mixins/notifications.mixin';
import { mixins } from 'vue-class-component';
import { TranslateResult } from 'vue-i18n';
import OnlineIndicator from '@/_ui/online-indicator/online-indicator.vue';
import {Getter, Action} from 'vuex-class';
import {TOpenEwSharerPayload} from '@/_store/ew-sharer.store';
import {TEvent} from '@/_types/event.type';
import DeviceHelper from '@/_helpers/device.helper';

@Component({
  components: {
    EwAvatar,
    iconUnFavorite,
    iconFavorite,
    iconShare,
    OnlineIndicator
  }
})
export default class Person extends mixins(NotificationsMixin) {

  @Getter('_eventStore/event') event: TEvent;
  @Getter('promoPageStore/contact') myself: TContact;
  @Action('ewSharerStore/openSharer') openSharer: (payload: TOpenEwSharerPayload) => void;
  @Action('ewSharerStore/closeSharer') closeSharer: () => void;

  @Prop()
  public readonly contact: TContact;

  @Prop({ type: Boolean, default: false })
  public readonly hideActions: boolean;

  @Prop({
    type: String,
    default: 'default',
    validator: (value: string) => {
      return ['default', 'small', 'calendar', 'speaker'].includes(value);
    }
  })
  public readonly personType: string;

  @Prop({
    type: String,
    default: 'offline',
    validator: (value: string) => {
      return ['online', 'offline', 'expired'].includes(value);
    }
  })
  public readonly indicatorType: string;

  public get isViewingSelf(): boolean {
    return !this.myself || (this.myself.id === this.contact.id);
  }

  @Prop({ type: Boolean, default: false })
  public readonly disableOnlineBadge: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly disableOnlineIndicator: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly disableUnreadCount: boolean;

  public get name(): string {
    return (this.contact && this.contact.name) || '';
  }

  public get surname(): string {
    return (this.contact && this.contact.surname) || '';
  }

  public get companyPosition(): string {
    return (this.contact && this.contact.company_position) || '';
  }

  public get companyName(): string {
    return (this.contact && this.contact.company_name) || '';
  }

  public get country(): string {
    return (this.contact && this.contact.country) || '';
  }

  public get city(): string {
    return (this.contact && this.contact.city) || '';
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get isExponent(): boolean {
    return !!this.contact.promopage_external_id;
  }

  public get isSpeaker(): boolean {
    return !!(this.contact && this.contact.program_ids && this.contact.program_ids.length);
  }

  public toggleFavorite(): void {
    if (!this.contact) {
      return;
    }
    if (this.contact.is_favorite) {
      this.$store.dispatch('contactsStore/removeFavContact', { contactId: this.contact.id });
    } else {
      this.$store.dispatch('contactsStore/addFavContact', { contactId: this.contact.id });
    }
  }

  public avatarSize(): number {
    switch (this.personType) {
      case 'small':
        return 40;
      case 'calendar':
        return 32;
      case 'speaker':
        return 24;
      case 'default':
      default:
        return 64;
    }
  }

  public get personStatus(): string {
    return this.isExponent ? 'exponent' : 'visitor';
  }

  public get personStatusTranslated(): TranslateResult {
    switch (this.personStatus) {
      case 'exponent':
        return this.$t('title.contactTypeExponent');
      case 'visitor':
        return this.$t('title.contactTypeVisitor');
      default:
        return this.$t('title.contactTypeVisitor');
    }
  }

  public sharePerson(event: Event): void {
    if (!navigator || !navigator.share || (!DeviceHelper.isAndroid() && !DeviceHelper.isIOS())) {
      this.openSharer({
        eventTarget: (event.target as Element),
        url: this.standaloneContactUrl,
        text: this.$t('nativeShareTextContact', { eventName: (this.event && this.event.title) || '' }) as string,
      });
      return;
    }

    navigator.share({
      url: this.standaloneContactUrl,
      text: this.$t('nativeShareTextContact', { eventName: (this.event && this.event.title) || '' }) as string,
    });
  }

  public isCustomHostname(): boolean {
    return /^(dev-web.eventswallet)|(local.eventswallet)|(app.eventswallet)|(www.eventswallet)/.test(window.location.host) !== true;
  }

  public getOpenGraphOrigin(): string {
    if (this.isCustomHostname()) {
      const domainZone = location.hostname.split('.').pop();
      return 'https://www.eventswallet.' + domainZone;
    }

    return window.location.origin.replace('app.', 'www.');
  }

  public get standaloneContactUrl(): string {
    if (!this.contact || !this.contact.id || !this.event) {
      return '';
    }
    const eventSlug = this.event.slug;
    return `${this.getOpenGraphOrigin()}/${this.$route.params.lang}/events/${eventSlug}/contact/${this.contact.id}`;
  }

  public get fullName(): TranslateResult | string {
    const fullNameJoined: string = [this.name, this.surname].filter(x => x).join(' ');
    return fullNameJoined || this.$t('contacts.info.noname');
  }

  public get contactGeoInfo(): string {
    return [this.city, this.country].filter(x => x).join(', ');
  }

  public onClickableCompanyNameClick(): void {
    if (!this.isExponent) {
      return;
    }

    this.$router.push({
      name: 'promo-page-events-company',
      params: {
        external_id: this.contact.promopage_external_id
      },
    }).catch(/* ignore */);
  }

}
